import createVueApp from '@phoenix/createVueApp';
import MegaMenuEventsCategory from '@maison/components/organisms/MegaMenuEventsCategory.vue';
import MegaMenuEventsCategoryTitle from '@maison/components/organisms/MegaMenuEventsCategoryTitle.vue';
import store from '@phoenix/store';

document.querySelectorAll('.phoenix-events-menu-category-title').forEach((el) => {
  const app = createVueApp({}, true);
  app.component('MegaMenuEventsCategoryTitle', MegaMenuEventsCategoryTitle);
  app.mount(el);
});

document.querySelectorAll('.phoenix-events-menu-list').forEach((el) => {
  const app = createVueApp({}, true);
  app.component('MegaMenuEventsCategory', MegaMenuEventsCategory);
  app.mount(el);
});

window.addEventListener('load', () => {
  document.querySelectorAll('[data-load-events]').forEach((el) => {
    el.addEventListener('click', (event) => {
      store.dispatch('eventsMenu/fetchData', event.target.dataset.loadEvents, { root: true });
    });
  });
});
