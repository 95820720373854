<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const hasFutureEvents = computed(() => store.getters['eventsMenu/hasFutureEvents']);
const transKey = computed(() =>
  hasFutureEvents.value ? 'Events.menu.futureEvents.title' : 'Events.menu.pastEvents.title'
);
const hasResults = computed(() => store.getters['eventsMenu/hasResults']);
</script>

<template>
  <template v-if="hasResults">
    {{ $t(transKey) }}
  </template>
</template>
