<script setup>
import EventLocation from '@maison/components/molecules/EventLocation.vue';
import PictureLazy from '@maison/components/common/PictureLazy.vue';
import Loader from '@phoenix/components/common/Loader.vue';
import { PhxIcon } from '@phx-styleguides/jlc';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  pageId: {
    type: String,
    required: true,
  },
  isTable: {
    type: Boolean,
    default: false,
  },
  itemsContainerClasses: {
    type: String,
    default: '',
  },
  containerClasses: {
    type: String,
    default: '',
  },
  locationsContainerClasses: {
    type: String,
    default: '',
  },
  linkClasses: {
    type: String,
    default: '',
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Object,
    default: () => ({}),
  },
});

const getEventId = ($id) => `event-id-${$id}`;

const store = useStore();
const pastEvents = computed(() => store.getters['eventsMenu/pastEvents']);
const futureEvents = computed(() => store.getters['eventsMenu/futureEvents']);
const hasFutureEvents = computed(() => store.getters['eventsMenu/hasFutureEvents']);
const events = computed(() => (hasFutureEvents.value ? futureEvents : pastEvents));
const isLoading = computed(() => store.getters['eventsMenu/isLoading']);
</script>

<template>
  <loader v-if="isLoading" theme="light" />
  <ul v-else :class="containerClasses">
    <li v-for="event in events.value" :key="event.id" :class="itemsContainerClasses">
      <div v-if="event.image && props.isMobile" class="picture">
        <picture-lazy v-bind="event.image"></picture-lazy>
      </div>
      <a
        :href="event.url"
        :data-tracking="event.tracking"
        class="mega-menu__link--emphasis mega-menu__link"
        :data-background-handler="event.image ? getEventId(event.id) : null"
      >
        <span>{{ event.name }}</span>
        <ul
          v-if="events.value"
          class="events-list events-list--table"
          :class="[locationsContainerClasses]"
          role="presentation"
        >
          <event-location
            v-for="location in event.locations"
            :key="location.locationName + location.startDate"
            :end-date="location.endDate"
            :start-date="location.startDate"
            :location="location.locationName"
            :override-date-label="location.label"
            :classes="['events-list__event']"
            is-list
          ></event-location>
        </ul>
      </a>

      <div
        v-if="event.image && !props.isMobile"
        class="mega-menu__background"
        :data-background-id="getEventId(event.id)"
        aria-hidden="true"
      >
        <div class="picture picture--cover">
          <picture-lazy v-bind="event.image"></picture-lazy>
        </div>
      </div>
    </li>
    <li :class="linkClasses">
      <a :href="link.url" class="btn btn--link btn--icon" :data-tracking="link.tracking">
        <span class="btn__text">{{ link.title }}</span>
        <span class="btn__icon">
          <phx-icon icon="chevron-10" bidirectional></phx-icon>
        </span>
      </a>
    </li>
  </ul>
</template>
