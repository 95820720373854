<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    size: {
      type: String,
      default: '',
      validator: (value) => ['', 'xs', 'sm', 'lg'].includes(value),
    },
    isSgphx: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    baseClass() {
      return this.isSgphx ? 'sgphx-loader' : 'loader';
    },

    hasSize() {
      return this.size !== '';
    },

    loaderClasses() {
      return [
        this.baseClass,
        `${this.baseClass}--${this.theme}`,
        { [`${this.baseClass}--${this.size}`]: this.hasSize },
      ];
    },

    innerClasses() {
      return `${this.baseClass}__inner`;
    },
  },
};
</script>

<template>
  <div :class="loaderClasses">
    <div :class="innerClasses"></div>
  </div>
</template>
